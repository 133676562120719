"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./client"), exports);
__exportStar(require("./wallet"), exports);
// Test code that can be executed in a browser to check that everything is OK there
// import * as _client from "./client";
// import * as _wallet from "./wallet";
// async function main() {
//     _client.CloudWalletHttpClient.endpointUrl = "http://127.0.0.1:5000";
//     const client = new _client.CloudWalletHttpClient("goerli", "Y2xvdWR3YWxsZXR1c2VyOiRhcHIxJENmRnI2dWt5JFJUTXowUi9MTHRNQjJWYy5OZXhTeTAK");
//     const wallet = await _wallet.CloudWallet.createWallet(client);
//     const signature = await wallet.signPayload(Buffer.from("aabbccdd", "hex"));
//     console.log(wallet.address);
//     console.log(signature);
// }
// main();
